.bri{
    width: 100%;
    background-color: rgba(243, 250, 255, 1);
}

.bri-calendar{
    width: 20%;
}

.bri-months{
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;
}