.login-main{
    background-color: rgba(243, 250, 255, 1);
    width: 100%;
    height: 100vh;
}

.login-header{
    display: flex;
}

.login-header img{
    padding: 3vh;
    width: 13%;
}

.login-form{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.login-form-username input{
    border: none;
    outline: none;
    background-color: rgba(242, 242, 242, 1);
    
}

.login-form-username{
    border: 1px solid rgba(242, 242, 242, 1);
    background-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    padding: 1vh;
    padding-inline: 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh;
}

.login-form-username img{
    width: 6%;
}

.login-card{
    background-color: white;
    width: 20%;
    padding: 5vh;
    margin: 5% auto;
    border-radius: 1vh;
}

.bfi-login-icon{
    width: 50%;
    margin-bottom: 2vh;
}