.navbar{
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchbar{
    background-color: #F3F7FA;
    padding: 1vh;
    border-radius: 1vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 13vw;
}

.searchbar input{
    border: none;
    outline: none;
    background-color: transparent;
    font-family: 'DM Sans', sans-serif;
}

.searchicon svg {
    color: #94A2BC;
}

.menu{
    display: flex;
    align-items: center;
}

.bellicon{
    font-size: larger;
    color: #768396;
}

.user{
    display: flex;
    align-items: center;
}

.userimg{
    width: 40%;
    background-color: #E2F3FF;
    border-radius: 50%;  
}

.downicon{
    color: #0072BC;
    padding-left: 2vh;
    width: 15%;
}

.bfi-icon{
    width: 45%;
    float: left;
    padding: 2vh;
}

.icons{
    padding-inline: 3vh;
    display: flex;
    justify-content: space-between;
    gap: 3vh;
}

/* 720p screens */
@media screen and (max-width: 1280px) and (max-height: 720px) and (min-height:300px) {
    .searchbar{
        width: 16vw;
    }
}