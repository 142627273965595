.sidebar{
    height: 100%;
    min-width: 15%;
    background-color: white;
}

.sidebar-menu{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1vh;
}

.sidebar-menu a{
    padding-block: 2vh;
    padding-inline: 2vh;
    margin-top: 1vh;
    color: #97BED0;
    border-radius: 1vh;
    text-decoration: none;
    font-weight: 500;
}

.sidebar-menu a:hover{
    background-color: #0072BC;
    color: white;
    transition-duration: 0.3s;
}

.active{
    background-color: #0072BC;
    color: white !important;
}

/* 720p screens */
@media screen and (max-width: 1280px) and (max-height: 720px) and (min-height:300px) {

}