.collapse {
    display: flex;
    width: 35vh;
    gap: 2vh;
    border-radius: 2vh;
    padding: 5vh;
    background-color: white;
    margin: 4vh;
    font-size: small;
    font-weight: 600;
    cursor: pointer;
}

.collapse-container {
    text-align: left;
    width: 100%;
}

.collapse-header {
    padding-bottom: 1vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collapse-expanded p {
    color: lightslategray;
    padding-block: 1vh;
}

.collapse-expanded p:hover {
    color: black;
}

.biome-collapse{
    display: flex;
    justify-content: space-between;
    padding: 3vh;
}

.biome-dataset{
    display: flex;
    justify-content: space-between;
    padding-block: 1vh;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    align-items: center;
}

.collapse-expanded{
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
}

.biome-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
}

.biome-item{
    font-size: 12px;
    padding: 1vh;
    border-radius: 2vh;
    padding-inline: 2vh;
    text-align: center;
    font-weight: 700;
}

.ant-input-biome{
    width: 40%;
    border: 1px solid #d9d9d9;
    background-color: transparent;
    margin-top: 5px;
    border-radius: 1vh;
}