.addData {
  width: 85%;
}

.addData-body {
  display: flex;
  width: 100%;
  gap: 5vh;
}

select {
  outline: none;
  border: 1px solid lightgray;
  border-radius: 2vh;
  padding-inline: 2vh;
  padding-block: 1vh;
}

.inputpart {
  display: flex;
}

.dashboard-form {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 3vh;
  background-color: #F3FAFF;
  border-radius: 3vh;
  margin-bottom: 5vh;
  margin-right: 2vh;
  overflow-x: auto;
  max-width: 60%;
}

.addData-head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.addData-head h1 {
  color: rgba(35, 35, 96, 1);
}

.addData-btn {
  margin-right: 2vh;
}

.form-data {
  background-color: #F3FAFF;
  padding: 3vh;
  border-radius: 2vh;
  height: max-content;
  width: 23%;
}

.addform label {
  font-size: small;
  font-weight: 800;
}

.addform input::placeholder {
  font-size: 12px;
  font-family: 'DM Sans';
}

.addform .input {
  font-family: 'DM Sans';
}

.addform textarea::placeholder {
  font-family: 'DM Sans';
}

.dashboard-form-head {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2vh;
}

.add-btn {
  display: flex;
  gap: 1vh;
  border: 1px solid gray;
  padding-block: 1vh;
  border-radius: 1vh;
  cursor: pointer;
  font-size: small;
  justify-content: space-between;
  padding-inline: 1vh;

}

.dashboard-body {
  display: flex;
  gap: 6vh;
}

.dashboardItems {
  width: 100%;
}

.input {
  padding-block: 1vh;
  padding-inline: 2vh;
  border-radius: 1vh;
  border: 1px solid #d9d9d9;
  width: 90%;
  background-color: transparent;
  margin-top: 5px;
}

.input:focus {
  background-color: white;
  outline: none;
}

.addform {
  margin-block: 1vh;
  padding-block: 1vh;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}

.ant-input {
  width: 98%;
  border: 1px solid #d9d9d9;
  background-color: transparent;
  margin-top: 5px;
  border-radius: 1vh;
}

.ant-input .ant-select-selector{
  background-color: transparent !important;
}

.ant-input .ant-select-selection-placeholder{
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  padding-inline: 1vh !important;
}

.ant-input input::placeholder{
  color: rgba(0, 0, 0, 0.6) !important;
}

.dbt-ant-input{
  width: 103%;
}

.cancel-btn{
  background-color: #F3F7FA;
  border: 1px solid #033C62;
  color: #033C62;
}