.biome{
    width: 100%;
    background-color: rgba(243, 250, 255, 1);
}

.biome-body {
    display: flex;
    margin: 3vh;
    margin-inline: 6vh;
    background: white;
    padding: 3vh;
    border-radius: 2vh;
    flex-direction: column;
}
.biome-data{
    display: flex;
}

.biome-task-list{
    border-radius: 2vh;
    display: flex;
    align-items: center;
    gap: 2vh;
    width: 100%;
}

.biome-badge p{
    background: rgba(245, 213, 122, 1);
    border-radius: 2vh;
    padding-inline: 2vh;
    padding-block: 2px;
    color: rgb(100, 100, 8);
}

.biome-dropdown img {
    width: 45%;
}

.biome-list-progress{
    display: flex;
    align-items: center;
    width: 100%;
}

.biome-progress{
    width: 100%;
}

.biome-task-name p{
    font-weight: 700;
}

.ant-progress {
    margin: 0;
    width: 90%;
}

.ant-progress-bg{
    height: 15px !important;
    background: linear-gradient(90deg, #97C354 53.64%, #F35757 68.23%);
}
