.timeline-tag{
    padding-inline: 2vh !important;
    border: 1px solid #0072BC !important;
    color: #0072BC;
}

.timeline-date-tag{
    color: #768396;
}

.timeline-tags{
    display: flex;
    align-items: center;
    margin: 4vh;

}

.tag{
    margin: 0;
    border: 1px solid #E3E3E3;
    width: fit-content;
    height: fit-content;
    border-radius: 20px;
    padding: 5px;
    font-size: small;
    margin-inline: 1vh;
    font-weight: 800;
}